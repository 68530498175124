
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.calculated-field {
  &__calculate {
    display: flex;
    align-items: center;
    gap: $base-gutter;
  }
}
